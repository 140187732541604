import { offset, flip, shift, FloatingArrow, arrow } from '@floating-ui/react'
import { useEffect, useRef, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { FaCheck } from 'react-icons/fa'
import Select from 'react-select'

import { Button, Input, OutsideClickContainer, useWithPopper } from 'simple-core-ui'

import s from './EditableContainerModal.scss'

interface Props {
  type?: string
  initialValue?: string
  optionValue?: { value: string; label: string } | ''
  onSubmit?: (value: string) => Promise<void>
  onCancel?: () => void
  error?: string
  isDisabled?: boolean
  testid?: string
  placeholder?: React.ReactNode
  validateChange?: (value: string) => string
  options?: { value: string; label: string }[]
}

const EditableContainerModal = ({
  type = 'text',
  initialValue = '',
  optionValue,
  placeholder = <p className={s.placeholder}>add...</p>,
  onSubmit,
  onCancel,
  error: initialError,
  validateChange,
  options
}: Props) => {
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState(initialError || '')
  const arrowRef = useRef(null)

  useEffect(() => {
    if (optionValue) setValue(optionValue?.value)
    else setValue(initialValue)
  }, [initialValue])

  const offsetValue = 10
  const middleware = [offset(offsetValue), flip(), shift()]

  middleware.push(
    arrow({
      element: arrowRef
    })
  )

  const {
    floatingStyles,
    setReference,
    setFloating,
    isPopperOpen,
    togglePopper,
    context
  } = useWithPopper({
    middleware,
    placement: 'top',
    handleState: true
  })

  const handleSave = () => {
    if (onSubmit) {
      try {
        onSubmit(value)
        setError('')
        togglePopper()
      } catch (err) {
        setError(error)
      }
    }
  }

  const handleCancel = () => {
    if (optionValue) setValue(optionValue?.value)
    else setValue(initialValue)
    setError('')
    if (onCancel) onCancel()
    togglePopper()
  }

  const handleClick = () => {
    if (!isPopperOpen || error) {
      togglePopper()
    }
  }

  useEffect(() => {
    setError(initialError || '')
  }, [initialError])

  useEffect(() => {
    if (error && !isPopperOpen) {
      togglePopper()
    }
  }, [error, isPopperOpen])

  return (
    <div className={s.editableContainer}>
      <OutsideClickContainer closeComponent={isPopperOpen ? handleCancel : () => {}}>
        <span ref={setReference} onClick={handleClick} className={s.initialValue}>
          {initialValue.trim() ? initialValue : placeholder}
        </span>

        {isPopperOpen ? (
          <div ref={setFloating} style={floatingStyles} className={s.popoverContent}>
            <div className={s.contentRow}>
              {options?.length ? (
                <Select
                  options={options}
                  value={
                    options.find((option: { value: string }) => option.value === value) || null
                  }
                  onChange={selectedOption => {
                    const newValue = selectedOption?.value || ''
                    setValue(newValue)
                    if (error) setError('')
                  }}
                  className={s.inputOptions}
                  styles={{
                    menu: provided => ({
                      ...provided,
                      maxHeight: '200px',
                      overflowY: 'auto'
                    })
                  }}
                />
              ) : (
                <Input
                  type={type}
                  text={value}
                  onChangeCb={e => {
                    let value = e.target.value
                    value = validateChange ? validateChange(value) : value
                    setValue(value)
                  }}
                />
              )}
              <Button onClick={handleSave} style={{ backgroundColor: '#57c787' }}>
                <FaCheck />
              </Button>
              <Button onClick={handleCancel} style={{ backgroundColor: '#ededed' }}>
                <IoMdClose style={{ color: 'black' }} />
              </Button>
            </div>
            {error && <span className={s.error}>{error}</span>}

            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill="#fff"
              stroke="#bbb"
              strokeWidth={1}
            />
          </div>
        ) : (
          ''
        )}
      </OutsideClickContainer>
    </div>
  )
}

export default EditableContainerModal
