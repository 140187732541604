export const SYSTEM_GENERATED = 'property'
export const SYSTEM_GENERATED_LABEL = 'System Generated'
export const LEGAL_ENTITY_ID = 'legal_entity'
export const MATTER_GROUP_ID = 'matter_group'
export const LEGAL_ENTITY = 'LegalEntity'
export const MATTER_GROUP = 'MatterGroup'
export const OTHER_ATTRIBUTE = 'Other_Attribute'
export const OTHER_ATTRIBUTE_VALUE_LIST = 'Other_Attribute_Value_List'
export const MATTER_SHORT_NAME = 'matter_short_name'
export const APPROVERS = 'approvers'
export const MAX_TEMPLATES_COUNT = 1000000

export const SCOPE = {
  VENDOR: 'vendor',
  MATTER: 'matter',
  LEGAL_ENTITY: 'legalEntity',
  PRACTICE_AREA: 'practiceArea',
  APPROVAL_WORKFLOW: 'approvalWorkflow',
  COMPANY: 'company'
}

export const SPECIAL_ACCESS_ROLES = [
  {
    systemName: '_cg', // CounselGO User
    type: 'vendor'
  },
  {
    systemName: '_cg_admin', // CounselGO Admin
    type: 'vendor',
    dependency: '_cg'
  },
  {
    systemName: '_vl', // Vendor Lead role
    type: 'client'
  },
  {
    systemName: '_ml', // Matter Lead role
    type: 'client'
  },
  {
    systemName: '_ml', // Matter Lead role
    type: 'legal_entity'
  }
]

export const TEMPLATE_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
  DELETED: 'deleted'
}

export const ORG_ROLE = {
  ADMIN: 'admin',
  FINANCE: 'finance',
  EMAIL_ONLY: 'emailonly',
  EMAIL_ONLY_FILTERED: 'emailonly_filtered',
  FILTERED: 'filtered',
  POWERUSER: 'poweruser',
  REQUEST_ONLY: 'requestonly'
}

export const INVOICE_EMAIL_PREFERENCE = {
  PER_INVOICE: 'per_invoice',
  PER_VENDOR: 'per_vendor',
  COMBINED: 'combined'
}

export const USER_SETTING = {
  LEGAL_ENTITY: 'legalEntity',
  USER_ROLE: 'userRole',
  USER_TEAMS: 'userTeams',
  ENHANCED_REPORTING_ACCESS: 'enhancedReportingAccess',
  APPROVAL_AUTHORITY: 'approvalAuthority',
  INVOICE_NOTIFICATIONS: 'invoiceNotifications',
  INVOICE_ESCALATION: 'invoiceEscalation',
  EMAIL_PREFERENCE: 'emailPreference',
  DEFAULT_TEMPLATE: 'defaultTemplate',
  LAST_LOGIN: 'lastLogin',
  FORCED_LOGOUT: 'forcedLogout',
  GOOGLE_CONNECT_URL: 'googleConnectUrl',
  CONNECTED_TO_GOOGLE: 'connectedToGoogle',
  CLIENT_USING_SSO: 'clientUsingSSO',
  TAB_ACCESS_CREATE: 'tabAccessCreate',
  TAB_ACCESS_DELETE: 'tabAccessDelete',
  LOGIN_PREFERENCE: 'loginPreference',
  CAN_EDIT_LOGIN_PREFERENCE: 'canEditLoginPreference',
  TIMEZONE: 'timezone'
}

export const MODULE = {
  SPEND: 'has_spend',
  INVOICES: 'has_invoices',
  ACCRUALS: 'has_accruals',
  BUDGETS: 'has_budgets',
  MATTERS: 'has_matters',
  LEGAL_REQUESTS: 'has_matter_requests',
  BUDGET_REQUESTS: 'has_budget_requests',
  CONFIDENTIAL_MATTERS: 'has_confidential_matters',
  INVOICE_REVIEWS: 'has_invoice_reviews',
  MATTER_REVIEWS: 'has_matter_reviews',
  INVOICE_AI: 'has_ai_invoice_flagging',
  TASKS: 'has_tasks',
  VENDOR_SURVEYS: 'has_vendor_surveys',
  TIMEKEEPERS: 'has_timekeepers',
  NATIVE_DMS: 'has_enhanced_document_management',
  EXTERNAL_DMS: 'has_document_management',
  TABLEAU: 'has_tableau',
  SIMPLESET_REPORTING: 'has_simpleset_reporting',
  MATTERS_OCR: 'has_matters_ocr',
  CUSTOMER_FACING_SIMPLE_REVIEW: 'has_customer_facing_simplereview',
  CUSTOMER_FACING_SIMPLE_REVIEW_EDITABLE: 'has_customer_facing_simplereview_editable',
  EVENT_MANAGEMENT: 'has_event_management',
  VISIBLE_DIVERSITY_REPORTS: 'visible_diversity_reports',
  NEW_TK_PROFILE_PAGE: 'has_new_tk_profile_page',
  TK_APPROVALS_PROFILE_ENHANCEMENT: 'has_tk_approval_profile_enhancement',
  AI_DOCUMENT_DRAFTING: 'has_ai_document_drafting',
  BULK_FILE_SHARING_WITH_VENDORS: 'has_bulk_file_sharing_with_vendors',
  INVOICE_LIST_CUSTOMIZED_COLUMNS: 'has_invoice_list_customized_columns'
}

export const DATE_FORMATS = {
  DJANGO_DATETIME: 'YYYY-MM-DDTHH:mm:ss.SSS',
  DJANGO_DATE: 'YYYY-MM-DD',
  DEFAULT_DATE: 'MM/DD/YYYY',
  DEFAULT_DATETIME: 'MM/dd/yyyy h:mm a',
  LONG_DATE: 'MMMM Do, YYYY',
  LONG_DATETIME: 'MMMM Do, YYYY h:mm A',
  DEFAULT_DATE_FNS: 'MM/dd/yyyy'
}

export const IMAGE_FORMATS = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.jif',
  '.jfif',
  '.jfi',
  '.png',
  '.gif',
  '.webp',
  '.tiff',
  '.tif',
  '.svg'
]

export const ENHANCED_REPORTING_ACCESS_MAPPING = [
  { label: 'None', value: 'None' },
  { label: 'Create', value: 'Create' },
  { label: 'View', value: 'View' }
]
